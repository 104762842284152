import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import ModAlertBanner from "../../../components/Chronology/Modules/ModAlertBanner";
import ModDesescalada from "../../../components/Chronology/Modules/ModDesescalada";
import ModGraphFull from "../../../components/Chronology/Modules/ModGraphFull";

import Day85 from "./dia-06-jun";
import Day86 from "./dia-07-jun";
import Day87 from "./dia-08-jun";
import Day88 from "./dia-09-jun";
import Day89 from "./dia-10-jun";
import Day90 from "./dia-11-jun";
import Day91 from "./dia-12-jun";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <ModAlertBanner color="brand02">
        Brasil supera los 40.000 fallecidos con Covid-19
      </ModAlertBanner>
      <ModDesescalada />
      <Day91 {...props} />
      <Day90 {...props} />
      <Day89 {...props} />
      <Day88 {...props} />
      <Day87 {...props} />
      <Day86 {...props} />
      <ModGraphFull
        name="mapa-fases-transicion-0606"
        alt="mapa transicion fases españa"
      />
      <Day85 {...props} />
    </LayoutWeek>
  );
};

export default Week;
