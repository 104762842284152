import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModBuscandoDatos from "../../../components/Chronology/Modules/ModBuscandoDatos";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 87",
  week: "Semana 13",
  day: "08",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-08",
  path: "/cronologia/semana-13#dia-08-jun/",
};

const Day87 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 2.157 casos mediante pruebas PCR. En ese mismo
          periodo, 145 personas han requerido hospitalización (6,7% de los
          diagnósticos), de los que 14 han sido ingresos en la UCI, y se han
          producido 56 fallecimientos.
        </ModText>

        <ModText>
          A partir hoy, 8 de junio, el 52% de la población española se encuentra
          en Fase 3 y el 48% en Fase 2.
        </ModText>
        <ModText>
          Ya se puede consultar en el Boletín Oficial del Estado la{" "}
          <InlineLink link="https://www.boe.es/boe/dias/2020/06/06/pdfs/BOE-A-2020-5795.pdf">
            Orden
          </InlineLink>{" "}
          que flexibiliza determinadas restricciones de ámbito nacional y
          establece los territorios que pasan hoy a Fase 2 y Fase 3 del Plan
          para la Transición hacia una Nueva Normalidad.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/sanidad14/Documents/2020/05062020_MapaFases5.pdf"
            name="Mapa de Transición a la Nueva Normalidad"
          />
          <ReferenceRow
            link="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/Plan_Transicion_Guia_Fase_2.pdf"
            name="Guía Fase 2"
          />
          <ReferenceRow
            link="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/Plan_Transicion_Guia_Fase_3.pdf"
            name="Guía Fase 3"
          />
        </ModReferenceList>
        <ModBuscandoDatos num="random" />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day87;
