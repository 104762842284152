import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModBuscandoDatos from "../../../components/Chronology/Modules/ModBuscandoDatos";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 91",
  week: "Semana 13",
  day: "13",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-13",
  path: "/cronologia/semana-13#dia-12-jun/",
};

const Day91 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.995 casos mediante pruebas PCR. En ese mismo
          periodo, 141 personas han requerido hospitalización (7,1% de los
          diagnósticos), de los que 10 han sido ingresos en la UCI, y se han
          producido 25 fallecimientos.
        </ModText>
        <ModImage
          src="/images/svg/12_may_cuarentena-fronteras.svg"
          alt="control sanitario fonteras"
        />

        <ModText>
          Comienzan a definirse las fechas de apertura de fornteras.
        </ModText>
        <ModDotList>
          Francia abrirá fornteras Shengen a partir del 1 de julio
        </ModDotList>
        <ModDotList>
          España evalue abrir la frontera con Portugal el 1 de julio
        </ModDotList>

        <ModBuscandoDatos num="random" />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day91;
